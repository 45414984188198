<template>
  <div class="freelancer-recommend">
    <div class="fr-title">
      <span class="fr-t-left">优秀人才推荐</span>
      <div class="fr-t-right" @click="fetchPersonLists">
        <Isvg icon-class="change" :class-name="isFetchingPerson ? 'icon-animate' : ''" />
        <span>换一换</span>
      </div>
    </div>
    <div class="fr-cards">
      <div class="card" v-for="(item, index) in personList" :key="index">
        <div class="card-top" @click="jumpDetailHandler(item.id)">
          <img :src="item.avatar" alt="head icon" />
          <div>
            <span>{{ item.name }}</span>
            <span>{{ item.hourly_rate }}</span>
          </div>
          <Isvg icon-class="platform-certification" class-name="platform-certification" />
        </div>
        <div class="card-main">
          <section class="cm-intro">{{ item.slogan }}</section>
          <div class="cm-com">
            <div>
              <StarList :total="item.score" />
              <span>{{ item.review_count }}</span>
            </div>
            <span>{{ item.service_count }}</span>
          </div>
        </div>
        <div class="card-bottom">
          <ul>
            <li
              class="cb-tag"
              v-for="(tag, idx) in item.skills"
              :key="idx"
              @click="jumpSkillHandler(tag.name)"
            >
              <span>{{ tag.name }}</span>
            </li>
          </ul>
          <div class="cb-more" @click="jumpDetailHandler(item.id)">查看详情</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { setMoneyComma } from '@/utils/transformData';
  import { StarList } from '@/_components/StarList';

  export default {
    name: 'FreelancerRecommend',
    components: { StarList },
    props: {
      info: {
        type: Array,
        default: () => []
      },
      isFetchingPerson: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      personList() {
        console.log();
        return this.info.map((item) => ({
          ...item,
          hourly_rate: item.hourly_rate.slice(1, -3) + '元/小时',
          review_count: `（${item.review_count}条评论）`,
          service_count: `${setMoneyComma(item.service_count, 0)}个项目`,
          score: +item.score.slice(0, -3)
        }));
      }
    },
    mounted() {},
    methods: {
      fetchPersonLists() {
        this.$emit('fetchPersonLists');
      },
      jumpDetailHandler(id) {
        this.$router.push('/freelancer/info/'+id)
      },
      jumpSkillHandler(id) {
        console.log(id);
      }
    }
  };
</script>

<style lang="less" scoped>
  .freelancer-recommend {
    //padding: 20px 0 100px;
    .main-width-center;
    margin-top: 18px;

    .fr-title {
      .flex;
      justify-content: space-between;

      & > span {
        color: #575757;
        line-height: 20px;
        .font-16;
      }

      .fr-t-right {
        .pointer;

        .icon-animate {
          animation: rotate 1.3s infinite;
        }

        & > span {
          vertical-align: middle;
          color: @color-blue;
          line-height: 20px;
          .font-14;
          margin-left: 6px;
        }
      }
    }

    .fr-cards {
      .flex;
      justify-content: left;
      margin-top: 10px;

      .card {
        width: 285px;
        height: 304px;
        margin: 0px 10px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0px 2px 4px 2px rgba(220, 221, 221, 0.5);
        &:first-of-type {
          margin-left: 0px !important;
        }
        &:last-of-type {
          margin-right: 0px !important;
        }
        .card-top {
          padding: 20px;
          background: linear-gradient(270deg, #00b4ff 0%, #00b4ff 0%, #1dd0d5 100%, #1dd0d5 100%);
          .flex;
          align-items: center;
          .relative;
          border-radius: 4px 4px 0 0;
          .pointer;

          & > img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
          }

          & > div {
            margin-left: 10px;

            & > span {
              display: block;
              max-width: 104px;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 24px;
              font-size: 18px;
              color: #fff;

              &:last-of-type {
                .font-14;
              }
            }
          }

          .platform-certification {
            .absolute;
            width: 24px;
            height: 24px;
            top: 27px;
            right: 20px;
          }
        }

        .card-main {
          padding: 10px 20px 18.5px;
          background-color: #fff;
          .box-border;
          height: 88.5px;
          position: relative;
          .border-bottom-1px(1px, 20px, 20px);
          .flex;
          flex-direction: column;
          justify-content: space-between;

          .cm-intro {
            font-size: 12px;
            word-break: break-all;
            max-height: 40px;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: justify;
            position: relative;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            color: #575757;
          }

          .cm-com {
            .flex;
            justify-content: space-between;
            .font-12;

            & > div {
              .flex;
              .align-center;
            }
          }
        }

        .card-bottom {
          padding: 12px 20px 20px;
          min-height: 111.5px;
          .relative;

          & > ul {
            display: flex;
            flex-wrap: wrap;
            & > li {
              .font-12;
              margin-top: 10px;
              color: #fff;
              .pointer;
              margin-right: 10px;

              & > span {
                padding: 2px 8px;
                border-radius: 10px;
                background: linear-gradient(
                  270deg,
                  #00b4ff 0%,
                  #00b4ff 0%,
                  #1dd0d5 100%,
                  #1dd0d5 100%
                );
                line-height: 16px;
                max-width: 120px;
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }

          .cb-more {
            .absolute;
            right: 20px;
            bottom: 20px;
            .font-12;
            color: @color-blue;
            .pointer;
          }
        }
      }
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
