<template>
  <div class="top-sort">
    <div class="sort-content" @click="isShowMore = !isShowMore">
      <span>{{ selectedSort }}</span>
      <Isvg icon-class="arrow-to-bottom" />
    </div>
    <transition v-on="on">
      <div class="sort-float" v-show="isShowMore">
        <ul>
          <li v-for="(item, index) in list" :key="index" @click="selectSortHandler(item)">{{
            item
          }}</li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
  import { addClass, removeClass } from '@/utils/dom';

  export default {
    name: 'DropDown',
    data() {
      return {
        isShowMore: false,
        selectedSort: this.list[0],
        on: {
          beforeEnter(el) {
            addClass(el, 'collapse-transition');
            if (!el.dataset) el.dataset = {};

            el.dataset.oldPaddingTop = el.style.paddingTop;
            el.dataset.oldPaddingBottom = el.style.paddingBottom;

            el.style.height = '0';
            el.style.paddingTop = 0;
            el.style.paddingBottom = 0;
          },

          enter(el) {
            el.dataset.oldOverflow = el.style.overflow;
            if (el.scrollHeight !== 0) {
              el.style.height = el.scrollHeight + 'px';
              el.style.paddingTop = el.dataset.oldPaddingTop;
              el.style.paddingBottom = el.dataset.oldPaddingBottom;
            } else {
              el.style.height = '';
              el.style.paddingTop = el.dataset.oldPaddingTop;
              el.style.paddingBottom = el.dataset.oldPaddingBottom;
            }

            el.style.overflow = 'hidden';
          },

          afterEnter(el) {
            // for safari: remove class then reset height is necessary
            removeClass(el, 'collapse-transition');
            el.style.height = '';
            el.style.overflow = el.dataset.oldOverflow;
          },

          beforeLeave(el) {
            if (!el.dataset) el.dataset = {};
            el.dataset.oldPaddingTop = el.style.paddingTop;
            el.dataset.oldPaddingBottom = el.style.paddingBottom;
            el.dataset.oldOverflow = el.style.overflow;

            el.style.height = el.scrollHeight + 'px';
            el.style.overflow = 'hidden';
          },

          leave(el) {
            if (el.scrollHeight !== 0) {
              // for safari: add class after set height, or it will jump to zero height suddenly, weired
              addClass(el, 'collapse-transition');
              // fix #968 collapse animation failure.
              // in vue3.0.4, transitionProperty is set 'none' to avoid 'v-leave-from' issue
              el.style.transitionProperty = 'height';
              el.style.height = 0;
              el.style.paddingTop = 0;
              el.style.paddingBottom = 0;
            }
          },

          afterLeave(el) {
            removeClass(el, 'collapse-transition');
            el.style.height = '';
            el.style.overflow = el.dataset.oldOverflow;
            el.style.paddingTop = el.dataset.oldPaddingTop;
            el.style.paddingBottom = el.dataset.oldPaddingBottom;
          }
        }
      };
    },
    props: ['list'],
    mounted() {
      document.addEventListener('click', this.resizeIsShowMore, true);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.resizeIsShowMore);
    },
    methods: {
      selectSortHandler(val) {
        this.selectedSort = val;
        this.isShowMore = false;
        this.$emit('selectSortHandler', val);
      },
      resizeIsShowMore() {
        this.isShowMore = false;
      }
    }
  };
</script>

<style lang="less" scoped>
  .top-sort {
    width: 160px;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    background: #ffffff;
    box-sizing: border-box;
    border: 1px solid #dcdddd;
    padding: 0 10px;
    position: relative;

    .sort-content {
      .flex;
      font-size: 12px;
      color: #b5b5b5;
      justify-content: space-between;
      align-items: center;
      .pointer;
    }

    .sort-float {
      position: absolute;
      left: -1px;
      top: 22px;
      background-color: #fff;
      border: 1px solid #dcdddd;
      border-radius: 0 0 4px 4px;
      width: 160px;
      z-index: 9;

      & > ul > li {
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        color: #b5b5b5;
        .pointer;
        padding: 0 10px;

        &:hover {
          background-color: #f8f8f8;
          color: @color-blue;
        }
      }
    }
  }
</style>
