<template>
  <div class="freelancer-banner">
    <div class="fb-content">
      <div class="logo">
        <img class="logo-img" :src="require('/public/static/img/pages/freelancer/freelancer-banner.png')" alt="logo" />
      </div>
      <div class="tagline">
        <div class="tagline-text"> 汇聚各行业优秀自由职业者，为您的企业成功提供助力 </div>
        <div class="tagline-button">
          <div class="button-release" @click="$Jump('/task/add')">免费发布任务</div>
          <div class="button-login" @click="loginHandler" v-if="!isLogin">成为自由职业者</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FreelancerBanner',
    computed: {
      isLogin() {
        return !!this.$store.getters['useUserStore/getToken'];
      }
    },
    methods: {
      loginHandler() {
        this.$store.dispatch('useUserStore/needLoginModal');
      }
    }
  };
</script>

<style lang="less" scoped>
  .freelancer-banner {
    width: 100vw;
    height: 160px;
    background: linear-gradient(90deg, #00b4ff 0%, #00b4ff 0%, #006cff 100%, #006cff 100%);
    min-width: @main-min-width;

    .fb-content {
      .relative;
      .main-width-center;

      .logo {
        .absolute;
        top: 20px;
        right: 0;
        width: 352px;
        height: 120px;
        .logo-img {
          width: 100%;
          height: 100%;
        }
      }

      .tagline {
        .absolute;
        left: 0;
        top: 40px;

        .tagline-text {
          font-size: 30px;
          font-weight: 900;
          line-height: 34px;
          color: #ffffff;
          text-shadow: 2px 4px 2px #005ed1;
        }

        .tagline-button {
          margin-top: 20px;
          .flex;

          div[class^='button'] {
            text-align: center;
            line-height: 30px;
            width: 160px;
            height: 30px;
            color: #fff;
            .font-14;
            .pointer;
          }

          .button-release {
            background: #ff008e;
          }

          .button-login {
            .box-border;
            margin-left: 30px;
            border: 1px solid #ffffff;
          }
        }
      }
    }
  }
</style>
